import React from 'react';
import ReactGA from "react-ga4";
import {Container, Navbar, Nav, Row, Col, Image} from 'react-bootstrap';
import { H, Section } from "react-headings";
import 'bootstrap/dist/css/bootstrap.min.css'
import Mailer from './mailer.js';
import Footer from './footer.js';
import './App.css';
ReactGA.initialize("G-Z5M4GD7KR9");

function App() {

  return (
    <div className='App'>
      <header>
      <Navbar fixed='top' collapseOnSelect expand='lg'>
        <Container>
          <Navbar.Brand href='http://structuralmelbourne.com.au/'>
              <img
                src='logo-horizontal.svg'
                className='d-inline-block align-top'
                alt='Structural Melbourne'
              />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className='me-auto'>
              <Nav.Link href='#whatdowedo'>What Do We Do?</Nav.Link>
              <Nav.Link href='#services'>Services</Nav.Link>
              <Nav.Link href='#contactus'>Contact Us</Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link eventKey={2} href='tel:+61458876290'>
                Call Us Today: 0458 876 290
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
    <main>
      <Container fluid className='bg-light'>
        <Container className='pt-5'>
          <Row className='pt-5 flex-row-reverse animate-opacity animate-bottom'>
            <Col lg={6} className='py-5 text-center justify-content-center align-self-center'>
              <Image
              src='img01.webp'
              fluid
              rounded
              alt='We Are Structural Melbourne'
              />
            </Col>
            <Col lg={6} className='pb-5 text-center justify-content-center align-self-center'>
              <Section component={<H> We Are Structural Melbourne</H>}>
              </Section>
                <p>
                <b>Structural Melbourne</b> provides <b>structural and civil engineering services</b>, specialising in <b>residential buildings</b>. Our goal is to produce cost effective <b>documentation</b> which leads to ease of <b>construction</b>.
                </p>
                <h2>Call Us Today: <a href='tel:+61458876290'>0458 876 290</a></h2>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className='bg-sanjuan' id='whatdowedo'>
        <Container className='pt-5'>
          <Row className='pt-5 flex-row animate-opacity animate-bottom'>
          <h2 className='text-center pb-5'> What Do We Do? </h2>
            <Col lg={6} className='text-center pb-5'>
              <Image
              src='img02.webp'
              fluid
              rounded
              alt='We Are Structural Melbourne'
              />
            </Col>
            <Col lg={6} className='pb-5 justify-content-center align-self-center'>
              <p><b>Structural Melbourne</b> provides <b>structural and civil engineering services</b>, specialising in <b>residential buildings</b>. Our goal is to produce cost effective <b>documentation</b> which leads to ease of <b>construction</b>. We can provide all of your <b>engineering</b> requirements from <b>soil report</b>, to final<b> certification</b> – all at <b>competitive rates</b>.</p>
              <p><b>Structural Melbourne</b> gets you your <b>structural design</b> quickly and affordably. We pride ourselves on developing and implementing structural <b>design solutions</b> that are innovative, practical and <b>economic</b>, that achieve our clients objectives, are <b>environmentally sustainable</b> and that comply with all current and relevant <b>Australian Standards</b>.</p>
              <p><b>Structural Melbourne</b> caters for a wide variety of <b>engineering consulting services</b>, including <b>residential</b>, <b>commercial</b> and <b>industrial projects</b>.  All projects utilise the latest computer software packages for <b>analysis, design and drafting</b>, all designs incorporate a large range of <b>materials</b> including; <b>timber, structural steel, masonry, block work and reinforced concrete</b>. <b>Structural Melbourne</b> offers highly <b>competitive</b> quotations. We provide the quickest turnarounds in <b>Melbourne</b>.</p>
              <p>We look forward to meeting and working with you on your next project – Welcome to <b>Structural Melbourne!</b></p>
            </Col>
            </Row>
          </Container>
      </Container>
      <Container fluid className='bg-light' id='services'>
        <Container className='pt-5'>
          <Row className='pt-5 flex-row animate-opacity animate-bottom'>
            <h2 className='text-center pb-5'> Our Services </h2>
            <Col lg={4} className='my-5 gx-5 text-left justify-content-center align-self-top animate-opacity animate-bottom'>
              <Image
              src='img03.webp'
              thumbnail
              className='mb-4'
              alt='Structural Melbourne Garages Alterations'
              />
              <ul>
              <li><h3> New homes </h3></li>
              <li><h3> Garages Alterations </h3></li>
              <li><h3> and Extensions </h3></li>
              </ul>
            </Col>
            <Col lg={4} className='my-5 gx-5 text-left justify-content-center align-self-top animate-opacity animate-bottom'>
              <Image
              src='img04.webp'
              thumbnail
              className='mb-4'
              alt='Structural Melbourne Townhouses'
              />
              <ul>
              <li><h3> Townhouses </h3></li>
              <li><h3> Footing Design </h3></li>
              <li><h3> Basements </h3></li>
              </ul>
            </Col>
            <Col lg={4} className='my-5 gx-5 text-left justify-content-center align-self-top animate-opacity animate-bottom'>
              <Image
              src='img05.webp'
              thumbnail
              className='mb-4'
              alt='Structural Melbourne Site Inspections'
              />
              <ul>
              <li><h3> Retaining walls </h3></li>
              <li><h3> Signage and lighting towers </h3></li>
              <li><h3> Site Inspections / Reports for Distressed Buildings </h3></li>
              </ul>
            </Col>
            </Row>
          </Container>
      </Container>
      <Container fluid className='bg-sanjuan' id='contactus'>
        <Container className='pt-5'>
            <Row className='pt-5 flex-row animate-opacity animate-bottom'>
              <h2 className='text-center pb-5'> Get in touch with us </h2>
              <Col sm={6} className='animate-opacity animate-bottom my-3'>
              <Mailer />
              </Col>
              <Col sm={6} className='animate-opacity animate-bottom my-3'>
              <p>
              <b>Address:</b> 65 Newlands Road, Coburg North VIC 3058 <br/>
              <b>Phone:</b> <a href='tel:+61458876290'  className='text-white'>0458 876 290</a> <br/>
              <b>Email:</b> <a href='mailto:info@structuralmelbourne.com.au'  className='text-white'>info@structuralmelbourne.com.au</a>
              </p>
              <a href='#' target='_blank' rel='noopener noreferrer'>
              <Image
              src='linkedin.svg'
              className='px-1 social'
              alt='Structural Melbourne linkedin'
              /></a>
              </Col>
            </Row>
        </Container>
      </Container>
      <Footer />
    </main>
    </div>
  );
}

export default App;
