import React, { useState } from 'react';
import {Button, Form, Alert} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha"

function onChange() {
    document.getElementById('send-button').disabled = false;
}
const Mailer = () => {
    function sendEmail(e){
        e.preventDefault();
        emailjs.sendForm(
            'service_9vlv7df', 
            'template_b9486gq', 
            e.target, 
            'W19rw2dUHNtpDrqzL'
        )
        .then(()=>
            {
                setShow(true)
                e.target.reset()
            })
        .catch(error=>
            console.log(error)
        )
        
    }
    const [show, setShow] = useState(false);
    return (
        <div className='container'>
            <h2 className='mt-3'>Contact Us</h2>

            <Alert show={show} variant="success" className='mt-2'>Your message has been sent successfully</Alert>
            <Form onSubmit={sendEmail}>

                <Form.Group className='mb-3' id='formBasicEmail'>

                <Form.Label>Name</Form.Label>
                <Form.Control 
                type="text" 
                placeholder="Name" 
                name='name' 
                id='formName'
                required/>
                
                <Form.Label>Email</Form.Label>
                <Form.Control 
                type='email' 
                placeholder='Enter email'
                name='user_email' 
                id='formUser_email'
                required/>

                <Form.Label>Message</Form.Label>
                <Form.Control 
                as="textarea" 
                rows={3} 
                type='message' 
                placeholder='message'  
                name='message' 
                id='formMessage'
                required />

                </Form.Group>

                <ReCAPTCHA sitekey={process.env.REACT_APP_SITEKEY} onChange={onChange} className='mb-3'/>

                <Button id='send-button' variant='primary' type='submit' value='send' className='bg-panache' disabled>
                send
                </Button>
            </Form>
        </div>
    );
};
export default Mailer;